<template>
  <div class="execute-detail-container">
    <div class="return-container">
      <a-button type="link" icon="arrow-left" @click="$router.push({name: 'ScriptExecuteHistory'})">返回</a-button>
    </div>

    <a-spin :spinning="spinning">
      <div v-if="mountedData.result" style="padding: 38px 60px 20px;">
        <header class="task-header">{{ taskDetail.name }}</header>
        <a-progress :percent="mountedData.current" :status="mountedData.current === 100 ? 'success' : 'active'"></a-progress>

        <a-descriptions class="custom-description" :column="{ xxl: 4, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }">
          <a-descriptions-item label="执行用户" >
            {{ taskDetail.created_by ? taskDetail.created_by.username : '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="执行状态">
            {{ taskDetail.finished ? '已完成' : '未完成' }}
          </a-descriptions-item>
          <a-descriptions-item label="创建时间">
            {{ taskDetail.created_at }}
          </a-descriptions-item>
          <a-descriptions-item v-if="taskDetail.script" label="脚本名称">
            {{ taskDetail.script.name }}
          </a-descriptions-item>
        </a-descriptions>

        <a-tabs v-model="activeTab" type="card" style="margin-top: 64px" @change="handleChange">
          <a-tab-pane v-for="item in tabList" :key="item.key" :tab="item.tab"></a-tab-pane>
        </a-tabs>

        <a-row :gutter="16">
          <a-col :span="12" style="height: 500px; margin-bottom: 16px; overflow: auto;">
            <a-table
              class="custom-table scroll-hidden-table"
              :columns="columns"
              :data-source="dataSource"
              :pagination="false"
              row-key="name"
              :scroll="{ x: scrollX }"
              :total="total"
            >
              <template slot="status" slot-scope="text">
                <span
                  :style="{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    marginRight: '8px',
                    background: getColor(text)
                  }"
                ></span>
                {{text | getStatus}}
              </template>
              <template slot="result" slot-scope="text">
                <a @click="changeResult(text)">查看</a>
              </template>
            </a-table>
          </a-col>
          <a-col :span="12" style="height: 500px; margin-bottom: 16px;">
            <code-mirror :value="code" readMode="nocursor"></code-mirror>
          </a-col>
        </a-row>

      </div>
      <div v-else style="height: 400px; position: relative; overflow: hidden;">
        <empty-component
          :imgUrl="require('@/assets/images/info_empty.png')"
          :body-style="{
            height: '220px',
            marginTop: '70px'
          }"
          :description-style="{
            marginTop: '-12px',
            marginRight: '8px',
            color: 'rgba(0,0,0,.55)'
          }"
          :show-description="false"
        >
          <span slot="customContent">加载失败，请稍后再试或 <a @click="$router.go(0)">重新加载</a></span>
        </empty-component>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { getScriptTask, getScriptTaskStatus } from '@/api/script-task'
import CodeMirror from '@/components/CodeMirror'
import EmptyComponent from '@/components/EmptyComponent.vue'

export default {
  name: 'ExecuteDetail',
  components: {
    CodeMirror,
    EmptyComponent
  },
  data () {
    return {
      spinning: false,
      activeTab: 'success',
      columns: [
        {
          dataIndex: 'name',
          title: '名称',
          width: 150,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'monitor_address',
          title: 'IP地址',
          width: 140,
          scopedSlots: {
            customRender: 'monitorAddress'
          }
        },
        {
          dataIndex: 'status',
          align: 'center',
          title: '执行状态',
          width: 100,
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          dataIndex: 'result',
          align: 'center',
          title: '执行结果',
          width: 80,
          scopedSlots: {
            customRender: 'result'
          }
        }
      ],
      dataSource: [],
      total: 0,
      taskDetail: {
        id: this.$route.params.id
      },
      taskStatusDetail: {},
      mountedData: {},
      code: '',
      timer: null
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    },
    tabList () {
      return [
        {
          tab: `执行成功（${this.mountedData.result.success.length}）`,
          key: 'success'
        },
        {
          tab: `执行失败（${this.mountedData.result.failed.length}）`,
          key: 'failed'
        },
        {
          tab: `正在执行（${this.mountedData.result.waiting.length}）`,
          key: 'waiting'
        }
      ]
    }
  },
  filters: {
    getStatus (v) {
      if (v === 'failed') return '执行失败'
      if (v === 'success') return '执行成功'
      if (v === 'waiting') return '等待执行'
      if (v === 'running') return '正在执行'
      return '未知'
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.spinning = true
      getScriptTask(this.taskDetail.id).then(res => {
        this.taskDetail = res.data
        if (this.taskDetail.finished) {
          this.mountedData = {
            current: 100,
            result: {
              success: this.taskDetail.result ? this.taskDetail.result.success : [],
              failed: this.taskDetail.result.failed ? this.taskDetail.result.failed : [],
              waiting: this.taskDetail.result.waiting ? this.taskDetail.result.waiting : []
            }
          }
          this.dataSource = this.taskDetail.result ? this.taskDetail.result.success : []
        } else {
          if (this.taskDetail.task_id) this.fetchTask()
        }
      }).finally(() => { this.spinning = false })
    },
    fetchTask () {
      getScriptTaskStatus({ id: this.taskDetail.task_id, task: 'script' }).then(res => {
        this.taskStatusDetail = res.data
        this.mountedData = {
          current: this.taskStatusDetail.current,
          result: {
            success: this.taskStatusDetail.data.success,
            failed: this.taskStatusDetail.data.failed,
            waiting: this.taskStatusDetail.data.waiting
          }
        }
        if (this.activeTab === 'success') this.dataSource = this.mountedData.result.success
        else if (this.activeTab === 'failed') this.dataSource = this.mountedData.result.failed
        else if (this.activeTab === 'waiting') this.dataSource = this.mountedData.result.waiting
        if (this.taskStatusDetail.current < 100) {
          this.timer = setInterval(() => {
            this.fetchTask()
          }, 5000)
        } else {
          if (this.timer) clearInterval(this.timer)
        }
      })
    },
    handleChange (v) {
      if (v === 'success') this.dataSource = this.mountedData.result.success
      else if (v === 'failed') this.dataSource = this.mountedData.result.failed
      else if (v === 'waiting') this.dataSource = this.mountedData.result.waiting
    },
    getColor (v) {
      if (v === 'success') return '#5FC367'
      else if (v === 'failed') return '#FF4D4F'
      else if (v === 'waiting') return '#d9d9d9'
      else if (v === 'running') return '#3AD3F9'
    },
    changeResult (res) {
      this.code = res
    }
  },
  destroyed () {
    if (this.timer) clearInterval(this.timer)
  }
}
</script>

<style lang="less">
.execute-detail-container {
  border-radius: 8px;
  background: #fff;
  min-height: calc(100vh - 158px);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;

  .return-container {
    height: 56px;
    border-bottom: 1px solid #d9d9d9;
    line-height: 56px;
  }

  .task-header {
    font-size: 18px;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
    line-height: 25px;
  }

  .custom-description {
    margin-top: 24px;
    .ant-descriptions-view {
      .ant-descriptions-item {
        font-size: 16px;
        padding-bottom: 8px;
      }
      .ant-descriptions-item-label {
        color: rgba(0, 0, 0, 0.5);
      }
      .ant-descriptions-item-content {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  .CodeMirror{
    min-height: 500px;
  }
}
</style>
